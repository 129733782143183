import styled from 'styled-components/macro';
import { Box, Heading, Paragraph } from '~styled';

import { transparentize, rgba } from 'polished';

const propToColor = (arg = '#1787FC') => transparentize(0.7, arg);

const Card = styled(Box).attrs({ className: 'card' })`
  border-radius: 10px;
  background-color: #ffffff;
  padding-top: 40px;
  padding-left: 45px;
  padding-right: 35px;
  padding-bottom: 28px;
  text-align: center;
  /* margin-bottom: 24px; */
  transition: 0.4s;
  width: 100%;
  height: 100%;
  border-color: transparent;
  display: inline-flex;
  flex-direction: column;
  &:hover {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  }
`;
Card.Icon = styled(Box)`
  display: flex;
  margin: 18px auto;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  max-width: 70px;
  min-height: 70px;
  max-height: 70px;
  border-radius: 10px;
  margin-bottom: 30px;
  font-size: 39px;
  background: ${props => rgba(props.iconBackground, 0.2)};
  color: ${props => props.iconBackground};
`;
Card.Title = styled(Heading)`
  color: #262729;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 5px;
  transition: 0.4s;
`;
Card.Text = styled(Paragraph)`
  opacity: 0.7;
  color: #262729;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
  align-self: self-end;
  margin-bottom: 0;
`;

export default Card;
